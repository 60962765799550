import { useState } from 'react'

import { AuthProvider } from '@descope/react-sdk'

import Error from './components/Error';
import AuthFlow from './components/AuthFlow';
import Navbar from './components/Navbar';
import Introduction from './components/Introduction';
import BottomNav from './components/BottomNav';
import Sample from './components/Sample';
import SDKShow from './components/SDKs';
import FlowDownload from './components/FlowDownload';
import NavbarModal from './components/NavbarModal';

import './App.css';

const defaultTheme = "light";
const defaultStyle = "theme";

const setURL = (theme, project, flow, postAuthFlow, style) => {
  var url = new URL(window.location.href);
  var search_params = url.searchParams;

  const currentTheme = search_params.get('theme') || defaultTheme;
  const newThemeExists = theme !== undefined && theme !== null && theme !== "";
  const isThemeChange = newThemeExists && currentTheme !== theme;

  search_params = checkSetParam('theme', search_params, theme, isThemeChange)
  search_params = checkSetParam('project', search_params, project, isThemeChange)
  search_params = checkSetParam('flow', search_params, flow, isThemeChange)
  search_params = checkSetParam('postAuthFlow', search_params, postAuthFlow, isThemeChange)
  search_params = checkSetParam('style', search_params, style, isThemeChange)

  url.search = search_params.toString();
  const new_url = url.toString();
  window.location.replace(new_url) 
}


const checkSetParam = (param, search_params, val, isThemeChange) => {
  let new_search = search_params

  if (val) { 
    new_search.set(param, val) 
  } else if (!isThemeChange){
    new_search.delete(param)
  }
  return new_search
}


function App() {
    
  const defaultProjectId = process.env.REACT_APP_DESCOPE_PROJECT_ID
  const baseURL = "https://auth.descope-explorer.com"

  const queryParameters = new URLSearchParams(window.location.search)
  const project = queryParameters.get("project") || defaultProjectId

  const adminProject = project === defaultProjectId

  const theme = queryParameters.get("theme") || defaultTheme
  const flow = queryParameters.get("flow") || "sign-up-or-in"
  const bgColorHex = queryParameters.get("bgcolorhex")

  const postAuthFlow = queryParameters.get("postAuthFlow") || null;
  const style = queryParameters.get("style") || defaultStyle;
  const validateOnBlur = queryParameters.get("validateOnBlur") || false;

  const [error, setError] = useState(!(theme === "dark" || theme === "light"))
  const [openModal, setOpenModal] = useState({open: false, modalType: ""});
  const [step, setStep] = useState(0);
  const [downloadFlow, setDownloadFlow] = useState(null);

  if (theme === "dark") {
    document.documentElement.setAttribute("data-theme", "dark")
  }

  return (
    <>
      {project && flow && !error ?
        <>
          <Navbar 
            theme={theme} 
            setURL={setURL} 
            setOpenModal={setOpenModal} 
          />
          <NavbarModal 
            openModal={openModal} 
            defaultFlow={flow} 
            defaultProjectId={project} 
            setOpenModal={setOpenModal} 
            setURL={setURL}
            postAuthFlow={postAuthFlow}
            defaultTheme={defaultTheme}
            styleId={style}
          />
          <Introduction theme={theme} />
            {adminProject && 
              <FlowDownload 
                defaultFlow={flow} 
                setURL={setURL} 
                theme={theme}
                step={step}
                downloadFlow={downloadFlow}
                setDownloadFlow={setDownloadFlow}
                styleId={style}
              />
            }
        <AuthProvider projectId={project}baseURL={baseURL}>
          <AuthFlow 
            defaultFlow={flow} 
            theme={theme} 
            setError={setError} 
            borderRounded = {adminProject}
            projectId={project}
            flowId={flow}
            baseURL={baseURL}
            step={step}
            setStep={setStep}
            downloadFlow={downloadFlow}
            postAuthFlow={postAuthFlow}
            styleId={style}
            bgColorHex={bgColorHex}
            validateOnBlur={validateOnBlur}
          />
        </AuthProvider>

            
          <SDKShow theme={theme} />
          <Sample />
          <BottomNav />
        </>
        :
        <Error />
      }
    </>
  )
}

export default App;