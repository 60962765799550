const isPostAuthFlow = (flowId) => {
    if (flowId !== undefined) {
        return flowId.startsWith('postauth_');
    }
    return false;
}

const getSelectStyle = (theme) => {
    const selectStyle = {
        control: base => ({
            ...base,
            border: 0,
            borderRadius: "10px",
            boxShadow: "none",
        }),
        dropdownIndicator: provided => ({
            ...provided,
            "svg": {
                fill: (theme === "light") ? "#fff" : "rgb(19, 221, 184)",
                padding: "5px",
            }
        }),
        option: base => {
            return {
              ...base,
              padding: "2vh 4vh"
        }},
        input: base => ({
            ...base,
            color: 'transparent',
            padding: '10px'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: (theme === "light") ? "#05587a" : "rgb(19, 221, 184)",
        }),
    }
    return selectStyle;
}

const decodeJWT = (res) => {
    var base65URL = res.split('.')
    var base64Data = base65URL[1].replace(/-/g, '+').replace(/_/g, '/')

    var jsonPayload = decodeURIComponent(atob(base64Data).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.stringify(JSON.parse(jsonPayload), null, 2)
  }

export { isPostAuthFlow, getSelectStyle, decodeJWT };