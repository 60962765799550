import { Descope } from '@descope/react-sdk';

function WrappedFlow(props) {
    const { flowId, theme, borderRounded, baseURL, bgColorHex, validateOnBlur, styleId } = props;
    return (
      <div className={borderRounded ? 'page flow-shown': "page flow-shown rounded-top"} style={bgColorHex ? {"background-color" : "#"+bgColorHex} : {}}  >
          <Descope
            flowId={flowId}
            baseURL={baseURL}
            theme={theme}
            styleId={styleId}
            validateOnBlur={String(validateOnBlur)}
          />
      </div>
    )
  }

  export default WrappedFlow;