import { useState, useEffect, useCallback } from 'react'

import Replay from '@mui/icons-material/Replay'

import '../App.css'
import { useEffectOnce } from 'usehooks-ts'
import { decodeJWT, isPostAuthFlow } from '../util/util'
import WrappedFlow from './WrappedFlow'
import { useSession } from '@descope/react-sdk';


function AuthFlow({ theme, setError, borderRounded, projectId, flowId, baseURL, step, setStep, downloadFlow, postAuthFlow, bgColorHex, validateOnBlur, styleId }) {
  
  const prefix = `${projectId}.${flowId}.`;

  const [jwt, setJwt] = useState("")
  const [response, setResponse] = useState("")
  const [localErrorMessage, setLocalErrorMessage] = useState("");
  const [flowSuccess, setFlowSuccess] = useState(false);

  const { isSessionLoading } = useSession()

  const logoutUser = useCallback(() => {
    window.location.reload();
  }, [])

  useEffectOnce(() => {
    window.analytics.track("Starting Flow",{'projectId':projectId, 'flowId': flowId});
  }, []);

  useEffect(() => {
		if (jwt) {
			window.localStorage.setItem(`${prefix}DS`, '');
			window.localStorage.setItem(`${prefix}DSR`, JSON.parse(response).refreshJwt);
		}
	}, [jwt, prefix, response]);

	useEffectOnce(() => () => {
		window.localStorage.removeItem(`${prefix}DS`);
		window.localStorage.removeItem(`${prefix}DSR`);
	});

  useEffect(() => {
		const ele = document.getElementsByTagName('descope-wc')[0];
    const onSuccess = (e) => onSuccessHandler(e);
    const onError = (e) => handleErrorSet(e, true);
		ele?.addEventListener('success', onSuccess);
		ele?.addEventListener('error', onError);
		return () => {
			ele?.removeEventListener('success', onSuccess);
			ele?.removeEventListener('error', onError);
		};
	}, [jwt, isSessionLoading]);

  const handleErrorSet = (e, isError) => {
    if (e.detail !== null && e.detail.errorCode === "E106003") {
      // If can't determine tenant from JWT, show error message
      const errorMessage = e.detail.message + `. A tenant and the "Tenant Admin" role are required to view this flow. Please contact Descope support for access.`;
      setLocalErrorMessage(errorMessage)
      return;
    } else if (e.detail !== null && e.detail.errorCode === "E102004") {
      // Handles "Flow requested is in old version, need to reload page 404 -  Error"
      setStep(2)
      setFlowSuccess(true)
    } else {
      setError(isError)
    }
  }
  const onSuccessHandler = (e) => {
    const obj = {
      email: e.detail.user.email,
      userId: e.detail.user.userId,
    }
    window.analytics.identify(e.detail.user.userId, obj);
    window.analytics.track('Success',obj);

    setJWTs(e);
  }
  const setJWTs = (e) => {

    if (postAuthFlow !== null || isPostAuthFlow(flowId)) {
      setStep(step + 1); // If post auth flow, show second screen
    } else {
      setStep(step + 2); // If not post auth flow, finish flow and show success screen
    }
    if (e.detail !== null) {
      // If JWT is returned, set JWT and response
      const response = JSON.stringify(e.detail, null, 2)
      const localJwt = decodeJWT(e.detail.sessionJwt)

      setResponse(response)
      if (localJwt !== undefined) {
        setJwt(localJwt)
      }
    } else {
      // If no JWT is returned, but flow is successful, set flowSuccess to true
      if (e.type === "success") {
        setFlowSuccess(true);
      }
    }
  }

  const renderWrappedFlow = (flow) => {
    return <WrappedFlow 
        projectId={projectId}
        flowId={flow}
        theme={theme}
        storagePrefix={prefix}
        borderRounded={borderRounded}
        baseURL={baseURL}
        bgColorHex={bgColorHex}
        validateOnBlur={String(validateOnBlur)}
        styleId = {styleId}
    />
  }

  const showFlow = () => {
    if (step > 1) {
      return null;
    }
    // Three cases:
    // 1. If post auth flow is given in url, use the flowId from the url as preauth
    // 2. If post auth flow is not given in url, check if the flowId is a post auth flow via underscore (ex: _otp-over-sms). Use default preAuthFlow then.
    // 3. If post auth flow is not given in url, and flowId is not a post auth flow, just show normal flow.

    if (postAuthFlow !== null) {
      if (jwt === "") {
        // First show basic auth for new user
        return renderWrappedFlow(flowId);
      } else {
        // After authentication show post-auth flow
        return renderWrappedFlow(postAuthFlow);
      }
    } else if (isPostAuthFlow(flowId)) {
      if (jwt === "") {
        // First show basic auth for new user
        return renderWrappedFlow(process.env.REACT_APP_DESCOPE_BASIC_AUTH_FLOW_ID);
      } else {
        // After authentication show post-auth flow
        return renderWrappedFlow(flowId);
      }
    } else {
      if (jwt === "") {
        // Show auth flow if user is not authenticated
        return renderWrappedFlow(flowId);
      }
      // Show nothing if user is authenticated
      return null;
    }
  }
  
  const getFlowId = () => {
    if (postAuthFlow !== null) {
      return postAuthFlow;
    } else if (isPostAuthFlow(flowId))  {
      return flowId.substring('postauth_'.length, flowId.length); // Removes initial underscore from flowId
    } else {
      return flowId;
    }
  }

  const getFlowName = () => {
    if (downloadFlow !== null && downloadFlow !== undefined) {
      return downloadFlow.name;
    }
    return getFlowId();
  }

  const postAuthFlowMessage = () => {
    if (jwt !== "") {
      return <p style={{ fontWeight: 'bold', marginTop: '15px' }}>
        **You're now viewing the '{getFlowName()}' flow.**
      </p>;
    } else {
      return <div style={{ margin: '15px', maxWidth: '900px', textAlign: 'center' }}>
        <p style={{ fontWeight: 'bold', textAlign: 'center', margin: 'auto' }}>
          The '{getFlowName()}' flow requires being an authenticated user. Please sign in first.
        </p>
      </div>
    }
  };
  const getDescription = () => {
    let html = "";
    if (downloadFlow) {
      const lmPos = downloadFlow.description?.indexOf("_LM:");
      if (lmPos !== -1) {
        const desc = downloadFlow.description.slice(0, lmPos);
        const link = downloadFlow.description.slice(lmPos+4);
        html = <p className='download-des'>{desc}<br></br><a target="_blank" href={link}>Learn More</a></p>

      } else {
        html = <p className='download-des'>{downloadFlow.description}</p>
      }
    }
    return html;
  }

  return (
    <div className='page authflow'>
      {localErrorMessage === "" ? <>
          {step < 2 && (isPostAuthFlow(flowId) || postAuthFlow !== null) && postAuthFlowMessage()}
          {getDescription()}
          {showFlow()}
        </>
        :
        <p style={{ color: 'red', maxWidth: '650px', margin: "0 15px 0 15px" }}>Error: {localErrorMessage}</p>}
        {jwt &&
          <button className='logout-btn' onClick={logoutUser} data-cy="logout"><Replay/></button>
        }
        {jwt && !flowSuccess && (
          <div className='row jwt-wrapper'>
            <div className='jwt-box'>
              <h3 className='jwt-title' data-cy="jwt-response">JWT Response</h3>
              <pre className='pre1'>{jwt}</pre>
            </div>
            <div className='jwt-box'>
              <h3 className='jwt-title'>Payload</h3>
              <pre className='pre2'>{response}</pre>
            </div>
          </div>
        )}
        {flowSuccess && <div className='success-message'>
          <h3>Success!</h3>
          <p style={{ margin: "auto" }}>You have completed the <i>`{getFlowId()}`</i> flow.</p>
        </div>}
    </div>
  )
}

export default AuthFlow
