import { useCopyToClipboard } from 'usehooks-ts'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

import '../App.css';


function Error() {
  const [value, copy] = useCopyToClipboard()
  const exampleText = `https://explorer.descope.com/?project=${process.env.REACT_APP_DESCOPE_PROJECT_ID}&flow=sign-up-or-in&theme=dark&style=new-style`

  return (
    <div className='page error fullscreen' data-cy="error-page">
      <h1 className='title error-title'>Hmmmmm</h1>
      <p>Why am I seeing this page?</p>
      <p className='error-txt'>Please make sure the URL is correctly formatted with a valid <span>project id</span>, <span>flow id</span>, <span>theme</span>, and <span>style id</span>.</p>
      <p className='error-blue'>Here's an example: </p>
      <p className='example' onClick={() => copy(exampleText)}>{exampleText}
        {value ?
          <CheckIcon className='copy-icon' />
          :
          <ContentCopyIcon className='copy-icon' />
        }
      </p>
    </div>
  )
}


export default Error
