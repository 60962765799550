import "../App.css"


function BottomNav() {
    return (
      <div className="page bottom-nav">
        <h1 className="bottom-title gradient">Powered by Descope</h1>
      </div>
    )
}


export default BottomNav