import { useEffect, useState } from "react"

import Modal from '@mui/material/Modal';

import "../App.css"


function NavbarModal ({ openModal, defaultProjectId, defaultFlow, setOpenModal, setURL, postAuthFlow, defaultTheme, styleId }) {
    const [settings, setSettings] = useState({
        settingProject: defaultProjectId,
        settingFlow: defaultFlow,
        settingPostAuthFlow: postAuthFlow,
        settingTheme: defaultTheme,
        settingStyle: styleId
    })

    const handleChange = (e) => {
        setSettings({
          ...settings,
          [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        var search_params = new URL(window.location.href).searchParams;
        const currentTheme = search_params.get("theme")
        const style = search_params.get("style")
        setSettings(prevSettings => ({
            ...prevSettings,
            settingTheme: currentTheme,
            settingStyle: styleId
        }))
    }, [])

    return (
        <Modal
            open={openModal.open}
            onClose={() => setOpenModal({open: false, modalType: ""})}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="page"
        >
            <div className="page question-modal">
                <h1 className="questions-modal-title">{openModal.modalType}</h1>
                {openModal.modalType === "Questions" ? 
                    <div className="inner-question-container">
                        <p className="question">What is Descope Explorer?</p>
                        <p>Descope Explorer is an easy way to demo your own project authentication flows.</p>
                        <p className="question">How can I add my own project id, flow id, style id, and theme?</p>
                        <p>1. Add it to the URL and format it like this: <br /> <br /><span className="question-ans-break">https://explorer.descope.com/?project={defaultProjectId}&flow=otp-over-sms&theme=dark&style=new-style</span></p>
                        <p className="question">OR</p>
                        <p>2. Click on the settings icon on the top right navbar to modify all the parameters through input fields.</p>
                    </div>
                    :
                    <>
                        <p className="settings-tagline">Try modifying the default values with your own project info!</p>
                        <div className="settings-form page">
                            <label className="settings-label">Project ID:</label>
                            <input type="text" name="settingProject" defaultValue={defaultProjectId} onChange={(e) => handleChange(e)} />
                            <label className="settings-label">Flow ID:</label>
                            <input type="text" name="settingFlow" defaultValue={defaultFlow} onChange={(e) => handleChange(e)} />
                            <label className="settings-label">Post Auth Flow ID (optional):</label>
                            <p style={{textAlign: 'left', fontSize: '14px'}}>This optional parameter allows previewing of flows that require authentication to view (eg. Step up, Update User, Invite Users).</p>
                            <input type="text" name="settingPostAuthFlow" defaultValue={postAuthFlow} onChange={(e) => handleChange(e)} />
                            <label className="settings-label">Style ID (optional):</label>
                            <p style={{textAlign: 'left', fontSize: '14px'}}>This optional parameter allows previewing of different flow styles.</p>
                            <input type="text" name="settingStyle" defaultValue={styleId} onChange={(e) => handleChange(e)} />
                        </div>
                        <button className="update-btn" onClick={() => setURL(settings.settingTheme, settings.settingProject, settings.settingFlow, settings.settingPostAuthFlow, settings.settingStyle)}>Update</button>
                    </>
                }
            </div>
        </Modal>
    )
}


export default NavbarModal